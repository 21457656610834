























































import { Component, Vue, Prop } from "vue-property-decorator";
import { UtilsPaciente } from "@/utils/utils-paciente";
import EstadisticasCitaModule from "@/store/modules/Dashboards/Empresarial/EstadisticasCita-module";
@Component({
  components: {}
})
export default class TablaCitasPacientes extends Vue {
  public searchText: string = "";
  /**
   * source
   */
  public get source() {
    if (this.searchText === "") {
      return EstadisticasCitaModule.EstadisticasCita.pacientes_y_tipos_cita;
    }
    return EstadisticasCitaModule.EstadisticasCita.pacientes_y_tipos_cita.filter(
      x => x.nombre.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
  /**
   * getimagen
   */
  public getimagen(sexo: string) {
    return UtilsPaciente.getrutaImagenFromString(sexo);
  }
}
